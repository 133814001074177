import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './modules/authModule'
import UsuariosModule from './modules/usuariosModule'
import SideBarModule from './modules/sideBarModule'
import {axiosbd} from "@/api/axios-bd"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    reporteVtaAyBDiria: false,
    reporteSaloneros: false,
    reporteSalonerosDetalle: false,
    reportePropinas: false,
    clasificacionRubros: [
      {id: 1, descripcion: 'Alimentos'},
      {id: 2, descripcion: 'Bebidas'},
      {id: 10, descripcion: 'Spa-jardin'},
    ],
    tipoArticulos: [
      {id: 1, descripcion: 'Alimentos'},
      {id: 2, descripcion: 'Bebidas'},
      {id: 3, descripcion: 'Descuento'},
    ],
    companyItems: [
      {id: 'am', descripcion: 'Tamarindo Diria'},
      {id: 'pjde', descripcion: 'Jardin del Eden'},
    ],
    rvcItems: [],
    rvcItemsAm: [],
    rvcItemsPjde: [],
    budgetItems: []
  },
  mutations: {
    MUT_ITENMS_WL(state, data){
      state[data.nombre] = data.data
    },
  },
  actions: {
    fechData(_, obj){
      // console.log(obj)
      return new Promise((resolve, reject) =>{
        axiosbd({
          method: obj.method,
          url: obj.url,
          data: obj.body ? obj.body : {},
          params: obj.params ?? undefined,
          headers: {
              Authorization: `Bearer ${obj.token}`,
          },
        })
        .then((res) => {
          // console.log(res.data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
      })
    },
    actItemsBaseWindowsList({commit}, data){
      // console.log(data)
      commit('MUT_ITENMS_WL', data)
    },


  },
  modules: {
    AuthModule,
    UsuariosModule,
    SideBarModule
  }
})