import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import {
  Message,
  MessageBox,
  Notification
} from 'element-ui';

Vue.use(VueSweetalert2)
Vue.config.productionTip = false
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;

Vue.prototype.$convertToMoney = function (x) {
  x = Number(x)      
  return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

Vue.prototype.$convertToMoneyNoDecimals = function (x) {
  x = Number(x)      
  return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')