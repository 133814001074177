const state = {
  toggleSidebar: true
}

const getters = {
  getActiveSidebar(state) {
    return state.toggleSidebar
  },
  getActiveNavbar(state) {
    return state.toggleNavbar
  },
}

const mutations = {
  mutActiveSidebar(state) {
    state.toggleSidebar = !state.toggleSidebar
  },
  mutActiveNavbar(state) {
    state.toggleNavbar = param
  },
}

const actions = {
  actActiveSidebar(context) {
    context.commit('mutActiveSidebar')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}