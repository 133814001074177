<template>
  <v-app id="vueDiria">
    <router-view></router-view>
  </v-app>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
export default {
  name: "App",
  components: {
    SideBar,
  },
  data: () => ({}),
  computed: {
    rutaActual() {
      const ruta = this.$router.currentRoute.path;
      return ruta;
    },
  },
};
</script>
<style>
/* Colores a los botones en las alertas */
.colorCncel{
  background-color: #fa0000 !important;
  border-color: #fa0000 !important;
  margin-left: 0.5rem;
}

.colorConfirm{
  background-color: #0063b4 !important;
  border-color: #0063b4 !important;
  margin-right: 0.5rem;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
